<!-- 修改登录密码 -->
<template>
  <div id="LoginPwd">
    <van-nav-bar :title="$t('m_header.xgdlmm')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.password"
        name="password"
        :label="$t('m_changeWithdrawPassword.jmm')"
        type="password"
        :placeholder="$t('m_modifyLoginPassword.qsrjmm')"
        maxlength="16"
        :rules="[{ required: true, message: $t('m_modifyLoginPassword.qsrjmm') }]"
      />
      <van-field
        v-model="form.new_password"
        name="password"
        :label="$t('m_modifyLoginPassword.xmm')"
        type="password"
        :placeholder="$t('m_modifyLoginPassword.xmmyz')"
        maxlength="16"
        :rules="[
          {
            required: true,
            validator,
            message: $t('m_modifyLoginPassword.xmmyz'),
          },
        ]"
      />
      <van-field
        v-model="form.renew_password"
        type="password"
        name="newPass"
        maxlength="16"
        :label="$t('m_modifyLoginPassword.qrxmm')"
        :placeholder="$t('m_modifyLoginPassword.xmmyz')"
        :rules="[
          {
            required: true,
            validator,
            message: $t('m_modifyLoginPassword.xmmyz'),
          },
        ]"
      />
      <div class="forgetPwd toService">
        {{$t('m_modifyLoginPassword.zhwyzfswjjmm')}}
        <router-link to="/cusService">{{$t('m_modifyLoginPassword.lxkf')}}</router-link>
      </div>
      <div style="margin: 16px">
        <van-button block class="btn" type="info" native-type="submit"
          >{{$t('m_modifyLoginPassword.qr')}}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { editPassword } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      form: {
        password: "",
        new_password: "",
        renew_password: "",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      return reg.test(val);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    onSubmit() {
      console.log("点击了提交");
      if (this.form.new_password + "" !== this.form.renew_password + "") {
        Toast.fail(this.$t('m_password.byz'));
        return;
      }
      this.editPasswordFun();
    },
    /* 修改密码 */
    editPasswordFun() {
      this.loadingCom();
      editPassword(this.form).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.code + "" === "201") {
          Toast(this.$t('m_password.mmcw'));
        } else {
          Toast.success(this.$t('m_cue.czcg'));
          setTimeout(() => {
            this.onClickLeft();
          }, 1000);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

/deep/.van-field__label {
  color: #ceab77;
}

.van-cell {
  padding: 15px 16px;
}

.van-cell::after {
  border-color: #404040;
}

.btn {
  background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
  font-size: 0.34rem;
  border: none;
}

.forgetPwd {
  font-size: 0.24rem;
  line-height: 0.6rem;
  text-align: right;
  color: #fff;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
</style>